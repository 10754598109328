function randomString(length) {
  var chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

  if (!length) {
    length = Math.floor(Math.random() * chars.length);
  }

  var str = '';
  for (var i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}
let uid = localStorage.getItem('uid');
if (!uid) {
  uid = randomString(8);
  localStorage.setItem('uid', uid);
}
const slog = {
  log: (str) => {
    window.logger.info(str);
  },
  info: (str) => {
    window.logger.info(str);
  },
  warn: (str) => {
    window.logger.warn(str);
  },
  error: (str) => {
    window.logger.error(str);
    try {
      if (str instanceof Error) {
        window._Sentry && window._Sentry.captureException(str);
      }
    } catch (error) {
      // console.error(error);
      // 处理错误
    }
  },
  capture(err) {
    try {
      window.logger.error(err);
      window._Sentry && window._Sentry.captureException(err);
    } catch (error) {
      // console.error(error);
      // 处理错误
    }
  }
};

module.exports = slog;

function _$(selector) {
  this.el = document.querySelector(selector);
}
_$.prototype.width = function () {
  return this.el.offsetWidth;
};
_$.prototype.height = function () {
  return this.el.offsetHeight;
};
_$.prototype.offset = function () {
  var rect = this.el.getBoundingClientRect();
  var offset = {
    ...rect,
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft
  };
  return offset;
};

_$.prototype.on = function (listener, callback) {
  this.el.addEventListener(listener, callback, false);
};

_$.prototype.off = function (listener, callback) {
  this.el.removeEventListener(listener, callback, false);
};

function $(selector) {
  return new _$(selector);
}

export default $;

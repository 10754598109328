import Ajax from './Ajax';
import * as mwx from '@/utils/common.js';
import { isDebuggerProduction, isMockData, platform } from './config';
const mockdata = require('./mockdata.js');

let apiCache = {};
async function cacheApi(cacheKey, fn) {
  if (apiCache[cacheKey]) {
    return apiCache[cacheKey];
  } else {
    let r = await fn();
    apiCache[cacheKey] = r;
    return r;
  }
}

var md5 = require('md5');

function handleFormData(data) {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData;
}

function getMd5(data) {
  let isFile = typeof data === 'string' ? false : true;
  return new Promise((resolve, reject) => {
    if (isFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          // 将读取到的数据转为Uint8Array
          const bytes = new Uint8Array(e.target.result);
          // 将Uint8Array转为字符串
          let str = '';
          for (let i = 0; i < bytes.byteLength; i++) {
            str += String.fromCharCode(bytes[i]);
          }
          // 计算 MD5 值
          resolve(md5(str));
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      // 以二进制形式读取文件
      reader.readAsArrayBuffer(data);
    } else {
      resolve(md5(data));
    }
  });
}

/* eslint-disable */
export async function checkImage(file, opt = {}) {
  // 默认开启检测
  let check = 1;
  opt.riskyCheck = 0;
  if (opt.riskyCheck !== undefined) {
    check = opt.riskyCheck;
  }
  if (!file) {
    return { checkStatus: -2, errMsg: 'file is empty' };
  }
  let mb = Math.ceil(file.length / 1024 / 1024);
  // console.log('上传图片大小', mb);
  if (mb > 10) {
    file = await mwx.useCompressImage({ imageSrc: file });
    // console.log('压缩后大小', file.length / 1024 / 1024);
  }

  let isFile = typeof file === 'string' ? false : true;
  let resoveCallback;
  if (!isFile) {
    if (window.__fileUrlMap && window.__fileUrlMap[file]) {
      let blobFileUrl = file;
      resoveCallback = () => {
        URL.revokeObjectURL(blobFileUrl);
      };
      console.log('blobFileUrl', blobFileUrl);
      file = window.__fileUrlMap[blobFileUrl];
      isFile = true;
      console.log('上传图片大小', Math.ceil(file.size / 1024 / 1024));
    }
  }
  // await mwx.getImgFileSize(file);
  let originFileNameList = '[]';
  if (opt.originFileNameList) {
    originFileNameList = JSON.stringify(opt.originFileNameList);
  }
  let cardInfo = mwx.action.getCardInfo() || {};
  let meta = '';
  if (cardInfo && cardInfo.name) {
    meta = cardInfo.name + ':' + (cardInfo.w + '*' + cardInfo.h);
  }
  let digest = '';
  try {
    let start = new Date();
    if (process.env.NODE_ENV !== 'production') {
      digest = await getMd5(file);
    }
    console.log('digest', digest, +new Date() - start);
  } catch (error) {
    console.error(error);
    // 处理错误
  }

  let formData = {
    [opt.permanent == 1 || !isFile ? 'file' : 'name']: file,
    // name: 'name',
    poi: opt.poi || 306,
    source: 2,
    meta: meta || '',
    riskyCheck: check ? 2 : 0,
    originFileNameList,
    origin: opt.origin || 0,
    digest
  };
  if (opt.permanent == 1) {
    formData = {
      ...formData,
      mediaType: 'image',
      temporary: 0,
      ignoreRisky: check ? 0 : 1,
      digest
    };
  }
  if (opt.mediaType == 'log') {
    formData = {
      file,
      mediaName: file.name,
      digest,
      poi: 0,
      mediaType: 'log',
      temporary: 0
    };
  }
  let fileInfo = '';
  if (file && file.size) {
    fileInfo = `name=${file.name},size=${file.size},type=${file.type}`;
  } else {
    try {
      fileInfo = fileInfo.slice(0, 200);
    } catch (error) {
      console.error(error);
      // 处理错误
    }
  }

  if (!isFile) {
    let imageInfo = await mwx.getImageInfo(file);
    formData.width = imageInfo.width;
    formData.height = imageInfo.height;
  }

  // console.log(
  //   `checkImage:opt=${JSON.stringify(
  //     opt
  //   )},isFile=${isFile},fileInfo=${fileInfo}`
  // );
  // console.log('file', file);

  let api =
    opt.permanent == 1
      ? isFile
        ? `/v5/media/upload?lang=zh`
        : `/v5/media/upload/base64?lang=zh`
      : isFile
      ? `/v5/image/upload?lang=zh`
      : `/v5/image/upload/base64?lang=zh`;
  let data = handleFormData(formData);
  return Ajax.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    disableErrorToast: opt.disableErrorToast
  })
    .then((r) => {
      if (check) {
        async function fn(timer) {
          let res;
          try {
            res = await (opt.permanent == 1
              ? v5MediaCheckAsync(
                  { mediaId: r.mediaId },
                  { disableErrorToast: true }
                )
              : v5ImageCheckAsync(
                  { fileName: r.fileName },
                  { disableErrorToast: true }
                ));
            timer && clearInterval(timer);
            return res;
          } catch (error) {
            if (error && error.code == 1009) {
              console.log('图片检测1009：', r.fileName);
              timer && clearInterval(timer);
              throw error;
            } else {
              return -1;
            }
          }
        }
        return new Promise(async (resolve, reject) => {
          let timer2 = setTimeout(() => {
            clearInterval(timer);
            resolve(r);
          }, 20 * 1000);
          let timer = setInterval(async () => {
            try {
              let t = await fn(timer);
              if (t != -1) {
                resolve(r);
              }
            } catch (error) {
              reject(error);
            }
          }, 500);
          try {
            let t = await fn(timer);
            if (t != -1) {
              resolve(r);
            }
          } catch (error) {
            reject(error);
          }
        });
      } else {
        return r;
      }
    })
    .then((res) => {
      resoveCallback && resoveCallback();
      try {
        if (res && !res.url) {
          mwx.slog.error('checkImage fail: ' + JSON.stringify(res));
        }
      } catch (error) {}
      if (res && (res.fileName || opt.permanent == 1)) {
        if (process.env.NODE_ENV === 'development' && !isDebuggerProduction) {
          res.url = res.url.replace(/^https/, 'http');
        }
        return { ...res, checkStatus: 1, errMsg: '图片链接不存在' };
      } else if (res == 1009) {
        return { checkStatus: -1, errMsg: '图片检测异常' };
      } else {
        mwx.log.error('checkImage fail: ' + JSON.stringify(res));
        return { checkStatus: -2, errMsg: '接口异常' };
      }
    })
    .catch((err) => {
      let errMsg = '';
      try {
        if (err && err.statusCode !== undefined) {
          errMsg += `statusCode=${err.statusCode},`;
        }
        if (err && err.message !== undefined) {
          errMsg += `message=${err.message},`;
        }
        if (err && err.errMsg !== undefined) {
          errMsg += `message=${err.errMsg},`;
        }
        errMsg = errMsg.replace(/,$/, '');
      } catch (error) {}
      mwx.log.error('checkImage fail:' + JSON.stringify(err));
      if (err) {
        if (err.code == 1009) {
          return { checkStatus: -1, errMsg: `code=1009`, error: err };
        } else if (err.statusCode == 413) {
          return { ...err, checkStatus: -2, errMsg, error: err };
        } else {
          if (typeof err == 'string') {
            // 后台接口返回的错误信息在data字段中
            err = {
              data: err
            };
          }
          return { checkStatus: -2, errMsg, error: err };
        }
      } else {
        return { checkStatus: -2, errMsg, error: err };
      }
    });

  return {
    confidence: 0,
    fileName: 'd200cb8503632f203fdcf2dfb80fcb8f',
    id: 110014056,
    lang: 'zh',
    share: 0,
    url: 'https://elfinbook-ocr.oss-cn-shanghai.aliyuncs.com/20230907/1916998/d200cb8503632f203fdcf2dfb80fcb8f.jpg?Expires=1694062547&OSSAccessKeyId=LTAICNPOgWg0xOHr&Signature=gLxRg2XEN7B93V2sDcdxVQXR2qo%3D',
    checkStatus: 1
  };
}
// https://www.showdoc.com.cn/1612231782571698/9960101930530353
export function v5ImageSegmentUrl(data, opt = {}) {
  let fileName = typeof data == 'object' ? data.fileName : data;
  // let map = {
  //   retouchPerson: 3,
  //   retouchBusiness: 4
  // };
  let segmentType = 3;
  if (opt.segmentType) {
    segmentType = opt.segmentType;
  }
  // if (data && data.type && map[data.type]) {
  //   segmentType = map[data.type];
  // }
  return Ajax.post(
    '/v5/image/segment/url',
    handleFormData({
      fileName,
      segmentType
    })
  );
}
export function cardFaceDetect(data) {
  return Ajax.post('/v5/card/face/detect', handleFormData(data));
}

export async function cardFaceCrop() {}

export async function cardFaceScore(data) {
  return Ajax.post('/v5/card/face/score', handleFormData(data));
}
export async function cardFaceEnhance(data) {
  return Ajax.post('/v5/card/face/enhance', handleFormData(data));
}

export async function wechatConfig() {
  return Ajax.get(`/wechat/config`, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function v5ImageCheckAsync(data, opt) {
  return Ajax.post('/v5/image/check/async', handleFormData(data));
}

export function v5MediaCheckAsync(data, opt) {
  return Ajax.post('/v5/media/check/async', handleFormData(data));
}

export function webUser() {
  return Ajax.get('/web/user');
}

export function cardFaceBeautyAsync(data, opt = {}) {
  return Ajax.post('/v5/card/face/beauty/async', handleFormData(data)).then(
    (r) => {
      async function fn(timer) {
        let res;
        try {
          res = await cardFaceTask(
            {
              fileName: data.fileName
            },
            {
              disableErrorToast: true
            }
          );
          timer && clearInterval(timer);
          return res.url;
        } catch (error) {
          if (error && error.code == 1009) {
            console.log('图片检测1009：', r.fileName);
            timer && clearInterval(timer);
            throw error;
          } else {
            return -1;
          }
        }
      }

      return new Promise(async (resolve, reject) => {
        let timer2 = setTimeout(() => {
          clearInterval(timer);
          reject('timeout');
        }, 20000);
        let timer = setInterval(async () => {
          try {
            let t = await fn(timer);
            if (t != -1) {
              clearInterval(timer2);
              resolve({ data: t, key: opt.key });
            }
          } catch (error) {
            reject(error);
          }
        }, 500);
        // getApp().globalData.cardFaceTaskTimer = timer;
        try {
          let t = await fn(timer);
          if (t != -1) {
            resolve({ data: t, key: opt.key });
          }
        } catch (error) {
          reject(error);
        }
      });
    }
  );
}

function cardFaceTask(data, opt) {
  return Ajax.get('/v5/card/face/task?fileName=' + data.fileName, {
    extraParam: opt
  });
}

export function cardMask(data) {
  return Ajax.post('/v5/card/mask', handleFormData(data));
}

// https://www.showdoc.com.cn/2272359968911745/10567092863645795
// const mockdata = require('./mockdata');
export function ecOrderDetail(data) {
  // data.shopId = localStorage.getItem('shopId') || '14681078';
  // return Promise.resolve(mockdata.data).then((r) => {
  //   r.skuList.forEach((v) => {
  //     v.template.isPld = mwx.pldSkyTypeList.indexOf(v.template.skuType) > -1;
  //   });
  //   return r;
  // });
  return Ajax.post('/v5/ec/order/detail', handleFormData(data)).then((r) => {
    // r = {
    //   goodsDetail: [],
    //   ioId: 840832,
    //   locked: false,
    //   skuList: [
    //     {
    //       name: '拍立得照片冲印测试链接',
    //       outerOiId: '4026252816819167049',
    //       pic: 'http://img.alicdn.com/bao/uploaded/i4/2214729285405/O1CN01Cx91gl1pnXhcDrc1L_!!0-item_pic.jpg_120x120.jpg',
    //       propertiesValue: '生活照6寸10张',
    //       qty: 2,
    //       skuId: 'X81066010',
    //       template: {
    //         allowBorder: 1,
    //         autoBeauty: 0,
    //         background: '',
    //         bgCount: 10,
    //         dpi: 0,
    //         inch: 6,
    //         mm: 'null*null',
    //         name: null,
    //         paperHeight: 152,
    //         paperType: '富士晶彩光面',
    //         paperWidth: 102,
    //         printCount: 1,
    //         requirement: null,
    //         shadow: 1.5,
    //         size: 'null*null',
    //         sizeRange: '',
    //         skuType: 2,
    //         templateId: 0,
    //         type: null,
    //         unitPrice: 0
    //       }
    //     },
    //     {
    //       name: '拍立得照片冲印测试链接',
    //       outerOiId: '4026252816820167049',
    //       pic: 'http://img.alicdn.com/bao/uploaded/i4/2214729285405/O1CN01Cx91gl1pnXhcDrc1L_!!0-item_pic.jpg_120x120.jpg',
    //       propertiesValue: '拍立得2张',
    //       qty: 1,
    //       skuId: 'X81050001',
    //       template: {
    //         allowBorder: 0,
    //         autoBeauty: 0,
    //         background: '',
    //         bgCount: 2,
    //         dpi: 0,
    //         inch: 3,
    //         mm: 'null*null',
    //         name: null,
    //         paperHeight: 89,
    //         paperType: '富士晶彩光面',
    //         paperWidth: 57,
    //         printCount: 1,
    //         requirement: null,
    //         shadow: 0.0,
    //         size: 'null*null',
    //         sizeRange: '',
    //         skuType: 3,
    //         templateId: 0,
    //         type: null,
    //         unitPrice: 0
    //       }
    //     },
    //     {
    //       name: '拍立得照片冲印测试链接',
    //       outerOiId: '4026252816821167049',
    //       pic: 'http://img.alicdn.com/bao/uploaded/i4/2214729285405/O1CN01Cx91gl1pnXhcDrc1L_!!0-item_pic.jpg_120x120.jpg',
    //       propertiesValue: '拼版冲印1张',
    //       qty: 2,
    //       skuId: 'X80070001',
    //       template: {
    //         allowBorder: 0,
    //         autoBeauty: 0,
    //         background: '',
    //         bgCount: 1,
    //         dpi: 0,
    //         inch: 5,
    //         mm: 'null*null',
    //         name: null,
    //         paperHeight: 127,
    //         paperType: '富士晶彩光面',
    //         paperWidth: 89,
    //         printCount: 1,
    //         requirement: null,
    //         shadow: 0.0,
    //         size: 'null*null',
    //         sizeRange: '',
    //         skuType: 4,
    //         templateId: 0,
    //         type: null,
    //         unitPrice: 0
    //       }
    //     }
    //   ],
    //   soId: '4026252816818167049'
    // };

    r.skuList.forEach((v) => {
      v.template.isCard = mwx.cardTypeList.indexOf(v.template.skuType) > -1;
      v.template.isPb = mwx.pbTypeList.indexOf(v.template.skuType) > -1;
      v.template.isPld = mwx.pldSkyTypeList.indexOf(v.template.skuType) > -1;
      v.template.isLife = mwx.lifeTypeList.indexOf(v.template.skuType) > -1;
      v.template.isLifePb = mwx.lifePbTypeList.indexOf(v.template.skuType) > -1;
      v.template.isSupportDivideUpload =
        mwx.supportDivideUploadList.indexOf(v.template.skuType) > -1;
      // skuType:https://www.showdoc.com.cn/2272359968911745/10188142564529487
      // TODO
      // 证件照
      // v.template.skuType = 1;
      // v.template.isPld = false;
      // v.qty = 1;

      // // 拼版
      // v.template.skuType = 4;
      // v.template.isPld = false;
      // v.qty = 1;

      // // 生活照
      // v.template.skuType = 2;
      // v.template.isPld = false;
      // v.qty = 2;

      // // 拍立得
      // v.template.skuType = 9;
      // v.template.isPld = true;
      // v.qty = 2;
    });
    return r;
  });
}

// https://www.showdoc.com.cn/2272359968911745/10567073441888170
export function cardTemplate(hot = 0) {
  return Ajax.get('/v5/card/template?hot=', hot);
}

// https://www.showdoc.com.cn/2272359968911745/10572329788194777
export function orderUpdate(data) {
  return Ajax.post('/v5/ec/order/update', data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export async function updateOrderData({
  addData,
  outerOiId,
  soId,
  addToLast = false
}) {
  let r = await ecOrderDetail({ soId });
  let skuItem = r.skuList.filter((v) => v.outerOiId == outerOiId)[0];
  let item = r.goodsDetail.filter((v) => v.outerOiId === outerOiId)[0];
  if (item) {
    let count = 0;
    item.list.forEach((v) => {
      count += v.qty * v.imageUrlList.length;
    });
    if (count + 1 <= skuItem.qty * skuItem.template.bgCount) {
      if (addToLast) {
        item.list = [...item.list, addData];
      } else {
        item.list = [addData, ...item.list];
      }
    } else {
      return -1;
    }
  } else {
    item = {
      outerOiId,
      list: [addData]
    };
    r.goodsDetail = [item, ...r.goodsDetail];
  }

  return orderUpdate(r);
}

// https://www.showdoc.com.cn/2272359968911745/10572336148972942
export function orderPrint(ioId, data) {
  return Ajax.post('/v5/ec/order/print?ioId=' + ioId, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function siteVisitorLogin(data) {
  if (isMockData) {
    return Promise.resolve(mockdata.loginData);
  }
  return Ajax.post(
    `/v5/ec/order/login?deviceId=${data.deviceId}&soId=${data.soId}`,
    {
      timeout: 10 * 1000
    }
  );
}

export function wechatUrl(data) {
  return Ajax.post(`/wechat/url?platform=${platform}`, handleFormData(data), {
    disableErrorToast: true
  });
}

function storePldSizeInfo(data) {
  try {
    let info = data.list[0].list[0];
    mwx.Store.set('pldSizeInfo', info);
  } catch (error) {}
}

let templateCache = {};
export function cardPolaroidTemplate({ skuType = 3 }) {
  if (templateCache[skuType]) {
    storePldSizeInfo(templateCache[skuType]);
    return Promise.resolve(templateCache[skuType]);
  }
  return Ajax.get('/v5/card/polaroid/template?skuType=' + skuType).then((r) => {
    if (process.env.NODE_ENV === 'development' && !isDebuggerProduction) {
      r.list.forEach((v) => {
        v.list.forEach((res) => {
          res.bigImageUrl = res.bigImageUrl.replace(/^https/, 'http');
          res.imageUrl = res.imageUrl.replace(/^https/, 'http');
          res.thumbImageUrl = res.thumbImageUrl.replace(/^https/, 'http');
        });
      });
    }

    templateCache[skuType] = r;
    storePldSizeInfo(r);
    return r;
  });
}

export function wechatLog(data) {
  return Ajax.post(
    '/wechat/log?mediaId=' + data.mediaId + '&warning=' + (data.warning || 0)
  );
}

// 转换后台返回的模板数据格式：https://www.showdoc.com.cn/2272359968911745/11416483408530180
// 转换为同 creativeTemplate的格式
function convertLifeTemplateList(list) {
  let r = list.map((item) => {
    let template = JSON.parse(item.template);
    return {
      bg: item.templateImageUrl,
      templateImageSrc: item.templateImageUrl,
      ratio: item.imageWidth / item.imageHeight,
      itemList: template.map((v) => {
        return {
          left: (v[0] / item.imageWidth) * 100,
          top: (v[1] / item.imageHeight) * 100,
          percent_width: (v[2] / item.imageWidth) * 100,
          percent_height: (v[3] / item.imageHeight) * 100
        };
      }),
      imageCount: item.imageCount,
      puzzleId: item.puzzleId,
      template,
      imageWidth: item.imageWidth,
      imageHeight: item.imageHeight
    };
  });
  return r;
}

export function v5EcPuzzleTemplate(skuType = 0) {
  let cacheKey = '/v5/ec/puzzle/template?skuType=' + skuType;
  return cacheApi(cacheKey, () =>
    Ajax.get(cacheKey).then((r) => {
      let res = convertLifeTemplateList(r.list);
      return res;
    })
  );
}

export function v5MediaCrop(data) {
  let cacheKey = JSON.stringify(data);
  return cacheApi(cacheKey, () =>
    Ajax.post(`/v5/media/crop`, handleFormData(data), {
      disableErrorToast: true
    })
  );
}

export function v5MediaRotate(data) {
  let cacheKey = JSON.stringify(data);
  return cacheApi(cacheKey, () =>
    Ajax.post(`/v5/media/rotate`, handleFormData(data), {
      disableErrorToast: true
    })
  );
}

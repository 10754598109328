const lifePhoto = {
  goodsDetail: [],
  ioId: 538606,
  locked: false,
  skuList: [
    {
      name: '易飞洗照片冲印打印冲洗拍立得定制冲印做成相册手机里的晒洗相片 【联系客服】上传照片 5寸—5张【新人尝鲜】',
      outerOiId: '291923666862_10089258872337',
      pic: 'https://img.alicdn.com/bao/uploaded/i1/2214729285405/O1CN01muifBe1pnXcijFunk_!!2214729285405.jpg',
      propertiesValue: '生活照5寸冲印5张',
      qty: 1,
      skuId: 'X81065005',
      template: {
        autoBeauty: 0,
        background: '',
        bgCount: 5,
        dpi: 0,
        inch: 5,
        mm: 'null*null',
        name: null,
        paperType: '富士晶彩光面',
        printCount: 1,
        requirement: null,
        size: 'null*null',
        sizeRange: '',
        skuType: 2,
        templateId: 0,
        type: null,
        unitPrice: 0
      }
    }
  ],
  soId: '291923666862_10089258872337'
};

const pldPhoto = {
  goodsDetail: [],
  ioId: 755474,
  locked: false,
  skuList: [
    {
      name: '照片冰箱贴定制diy磁性贴拍立得磁吸贴明星小卡宝宝相片创意装饰',
      outerOiId: '240801-016357845340542_1600948378059',
      pic: 'https://img.pddpic.com/open-gw/2024-05-25/733b1ab8-7b52-4376-84a6-d25a4dfbdcc9.jpeg',
      propertiesValue: '拍立得冰箱贴【十种热门边框可选】,10张【自带磁性】',
      qty: 1,
      skuId: 'Y8240100002',
      template: {
        allowBorder: 0,
        autoBeauty: 0,
        background: '',
        bgCount: 10,
        dpi: 0,
        inch: 3,
        mm: 'null*null',
        name: null,
        paperHeight: 89,
        paperType: '富士晶彩光面',
        paperWidth: 57,
        printCount: 1,
        requirement: null,
        shadow: 0.0,
        size: 'null*null',
        sizeRange: '',
        skuType: 3,
        templateId: 0,
        type: null,
        unitPrice: 0
      }
    }
  ],
  soId: '240801-016357845340542_1600948378059'
};

const loginData = {
  app: 0,
  appletStar: 0,
  baseSpaceSize: 524288000,
  cloudSpaceSize: 524288000,
  email: null,
  expireDescription: '解锁所有高级权益',
  expireTime: 0,
  headImg: null,
  language: 'zh',
  level: 0,
  newUser: 1,
  nickname: null,
  openId: null,
  phoneNumber: null,
  prefix: 0,
  purchaseCloudSpaceSize: 0,
  subscribe: 0,
  tabList: [],
  token:
    'T_tL5eR3fgj6yzzTWE8NDUjI53yzBxxKL-TSGSifT9pONDVb_DAhC8Dxp_TgblL5NCKq7TWhBMNzhJ9TGibsu_wRcIB50OH453HJa2YCydMb3fHEdRw_5xHZzYVhb5CFbbXaPkXTpnddGodaHjllPg==',
  uid: 16884526,
  usedSpaceSize: 0,
  username: null,
  vipCloudSpaceSize: 0
};

module.exports = {
  lifePhoto,
  pldPhoto,
  loginData
};

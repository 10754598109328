<template>
  <div id="app" style="position: relative">
    <transition :name="transitionName">
      <keep-alive :include="includeList">
        <router-view :key="key" class="Router" />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
import { setTitle } from './utils/common';
import * as mwx from '@/utils/common.js';

export default {
  name: 'AppMain',
  data() {
    return {
      includeList: [
        'createCard',
        'saveImage',
        'selectCard',
        'addPhoto',
        'selectTemplate'
      ],
      transitionName: '',
      isFirstTime: true
    };
  },
  computed: {
    key() {
      return this.$route.path;
    }
  },
  // watch $route 决定使用哪种过渡
  watch: {
    key() {
      if (this.$route.meta.title) {
        setTitle(this.$route.meta.title);
      }
    },
    $route(to, from) {
      // if (['skuDetail', 'createCard', 'saveImage'].indexOf(to.name) > -1) {
      //   let map = mwx.action.getHistoryStackMap() || {};
      //   map[to.name] = window.history.length;
      //   mwx.action.setHistoryStackMap(map);
      // }
      if (this.isFirstTime) {
        this.isFirstTime = false;
        return;
      }
      if (to.name == 'cardDetail' && from.name == 'createCard') {
        console.log('rendering card detail');
        this.includeList = [
          'cardDetail',
          'saveImage',
          'selectCard',
          'addPhoto',
          'selectTemplate'
        ];
      } else {
        this.includeList = [
          'cardDetail',
          'createCard',
          'saveImage',
          'selectCard',
          'addPhoto',
          'selectTemplate'
        ];
      }
      // console.log(+to.query.t, +from.query.t);
      let isForward = +(to.query.t || 0) > +(from.query.t || 0);
      // console.log('isForward', isForward);
      this.transitionName = isForward ? 'slide-left' : 'slide-right';
    }
  }
};
</script>
<style scoped>
.Router {
  position: absolute;
  width: 100%;
  transition: all 0.3s ease;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.slide-left-enter-active,
.slide-right-leave-active {
  transition: transform 0.2s;
}

.slide-left-enter, .slide-right-leave-to /* .fade-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}

.slide-left-leave, .slide-right-enter /* .fade-enter-active in <2.1.8 */ {
  transform: translateX(-100%);
}
</style>

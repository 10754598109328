let cache = {};
let isError = false;
window.__myCache = cache;
var store = require('store');
import slog from './slog.js';
let Store = {
  get(key) {
    let r =
      isError && cache[key] !== undefined
        ? cache[key]
        : cache[key] || store.get(key);
    return r;
  },
  set(key, v) {
    let val;
    if (v !== null && typeof v === 'object') {
      // 同一个引用类型会引发问题，如外部修改了引用的对象，会导致store中的对象也被修改
      val = JSON.parse(JSON.stringify(v));
    } else {
      val = v;
    }
    // if (
    //   key == 'uploadPhotoList1' &&
    //   Array.isArray(val) &&
    //   val.length == 0 &&
    //   window.isT
    // ) {
    //   debugger;
    // }
    cache[key] = val;
    try {
      store.set(key, val);
    } catch (error) {
      isError = true;
      try {
        console.error('set Store error', error);
        // slog.error('--set Store error--', key, error);
      } catch (error) {}
    }
  },
  remove(key) {
    // store.remove(key);
    cache[key] = undefined;
    store.remove(key);
  },
  clear() {
    // store.clearAll();
    // console.log('---clear---');
    cache = {};
    store.clearAll();
  }
};
// window.__Store__ = cache;
export default Store;

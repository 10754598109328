(function (doc, win) {
  var docEl = doc.documentElement;
  var resizeEvt =
    'orientationchange' in window ? 'orientationchange' : 'resize';
  var recalc = function () {
    if (window.__setFontSize__ == 'disable') {
      return;
    }
    docEl.style.fontSize =
      (Math.min(document.documentElement.clientWidth, 600) / 7.5) * 2 + 'px';
  };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);

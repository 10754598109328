let reportList = [];

// 统计上报代码是异步加载的，但是上报事件可能已经触发。需要先存储上报事件，等到已经挂载后再进行上报
function timingCheck(count = 0) {
  // 检测超时时间6s
  if (count > 60) {
    return;
  }
  let timer = setTimeout(() => {
    if (window.TDAPP) {
      var reportObj = reportList.shift();
      while (reportObj) {
        let { eventId, label, data } = reportObj;
        window.TDAPP.onEvent(eventId, label, data);
        // console.log('report：\n', eventId, label, data);
        reportObj = reportList.shift();
      }
    } else {
      clearTimeout(timer);
      timer = null;
      timingCheck(count++);
    }
  }, 100);
}

/**
 * 统计上报  http://doc.talkingdata.com/posts/36
 * @param {*} eventId  事件id
 * @param {string} [label='']  分类字段
 * @param {*} [data={}]   参数
 */
function report(eventId, label = '', data = {}) {
  try {
    if (typeof data == 'string') {
      throw TypeError('data必须是对象');
    }
    data = {
      ...data
    };
    if (window.TDAPP) {
      data = {
        ...data,
        isPc: !navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      };
      // console.log('report：\n', eventId, label, data);
      window.TDAPP.onEvent(eventId, label, data);
    } else {
      reportList.push({
        eventId,
        label,
        data
      });
      timingCheck();
    }
  } catch (error) {
    console.log(error);
  }
}

export default report;

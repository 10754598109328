import Toast from 'mint-ui/lib/toast';
import 'mint-ui/lib/toast/style.css';

function _Toast(message) {
  Toast({
    message,
    duration: 1500
  });
}

export default _Toast;

let mock = false;
let baseURL;
let isDebuggerProduction = false; // 本地调试线上环境
// let baseURL = '//api.elfinbook.com/';
// let baseURL = '///192.168.50.235:80/api';true
// let baseURL = '//api.book.com/';

let platform = 45;

if (process.env.NODE_ENV != 'production') {
  let proxyUrl = isDebuggerProduction
    ? 'https://api.elfinbook.com/'
    : 'http://api.book.com/';
  if (location.origin.indexOf('192.168') > -1) {
    baseURL = '//192.168.50.205:8080/' + proxyUrl;
  } else {
    baseURL = proxyUrl;
  }
} else {
  baseURL = '//api.elfinbook.com/';
}

let redirectURL = '//www.elfinbook.com';
let isMockData = process.env.NODE_ENV != 'production' && false; // TODO: 通过ip访问

export {
  mock,
  baseURL,
  redirectURL,
  platform,
  isDebuggerProduction,
  isMockData
};
